import "./App.css";
import Hero from "./Component/Hero/Hero";
import logo from "./logo.svg";
import Program from "./Component/Program/Program";
import Reasons from "./Component/Reasons/Reasons";
import Plans from "./Component/Plans/Plans";
import Testimonial from "./Component/Testimonial/Testimonial";
import Join from "./Component/Join/Join";
import Footer from "./Component/Footer/Footer";
function App() {
  return (
    <div className="App">
      <Hero />
      <Program />
      <Reasons />
      <Plans />
      <Testimonial />
      <Join />
      <Footer />
    </div>
  );
}

export default App;
